<template>
  <div id="app">
    <section class="section position-relative popUpFather">
      <div class="mt-4 flex-wrap justify-content-between">
        <div class="p-0 col-sm-auto mb-2">
          <div class="p-0 col-sm-auto row m-0">
            <div class="d-flex my-auto">
              <div class="my-auto mr-2">
                <h4 class="card-title my-auto">Liste des FDR</h4>
              </div>
              <DropDownCustom
                :items="['Tableau', 'Kanban', 'Gantt']"
                class="my-auto"
                @changeValue="onValueChange"
              />
            </div>
            <div class="my-auto ml-2">
              <b-dropdown
                v-if="selected == 2"
                id="dropdown-1"
                :text="selectedView.text"
                :class="`custom-drop m-md-2 btn-outline-info ${selectedView.color} `"
              >
                <b-dropdown-item
                  class="col-sm-6 col-md-auto m-0 p-0"
                  v-for="(view, index) in ganttView"
                  :key="index"
                >
                  <button
                    @click="changeView(index)"
                    type="button"
                    :class="`btn ${view.color} btn-fw col-sm-12`"
                  >
                    {{ view.text }}
                  </button>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div
              class="row ml-auto p-0 col-12 col-xxl-auto mr-0 py-1 my-auto"
              style="place-content: end"
            >
              <div
                class="p-0 col-md-auto my-auto ml-auto"
                style="height: 44.22px; width: 13.33% !important"
              >
                <b-form-input
                  type="text"
                  v-model="searchQuery.libelle"
                  placeholder="Libellé FdR"
                  class="h-100"
                ></b-form-input>
              </div>
              <div
                class="p-0 pl-2 col-md-auto my-auto"
                style="height: 44.22px; width: 13.33% !important"
              >
                <v-select
                  label="value"
                  v-model="searchQuery.responsable"
                  :options="responsables"
                  :reduce="(responsable) => responsable.id"
                  class="bg-white h-100"
                  placeholder="Responsable"
                >
                  <template #selected-option="{ value }">
                    <span class="text-truncate col-12 p-0">{{
                      value
                    }}</span></template
                  ></v-select
                >
              </div>
              <div
                class="p-0 pl-2 col-md-auto my-auto"
                style="height: 44.22px; width: 13.33% !important"
              >
                <date-picker
                  :value="new Date()"
                  v-model="searchQuery.startedAt"
                  placeholder="Date début"
                  format="DD/MM/YYYY"
                />
              </div>
              <div
                class="p-0 pl-2 col-md-auto my-auto"
                style="height: 44.22px; width: 13.33% !important"
              >
                <date-picker
                  placeholder="Date fin"
                  :value="new Date()"
                  v-model="searchQuery.endingAt"
                  class="bg-white h-100"
                  format="DD/MM/YYYY"
                />
              </div>
              <div
                class="p-0 pl-2 col-md-auto my-auto"
                style="height: 44.22px; width: 13.33% !important"
              >
                <v-select
                  :options="statusFdr"
                  v-model="searchQuery.status"
                  placeholder="Statut"
                  appendToBody
                  class="bg-white h-100"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <div
                class="p-0 pl-2 col-md-auto my-auto"
                style="height: 44.22px; width: 13.33% !important"
              >
                <v-select
                  type="text"
                  v-model="searchQuery.echeance"
                  placeholder="Statut échéance "
                  :options="['Nouveau', 'En retard', 'En cours']"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <div
                v-if="isAdmin || isSuper || isManager || isPo"
                class="p-0 pl-2 col-md-auto my-auto"
                style="width: 20% !important"
              >
                <CustomAddButton
                  class="w-100 text-center px-2"
                  text="Nouvelle feuille de route"
                  @click="$router.push({ name: 'add-fdr' })"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-0 bg-secondary" />

      <div v-if="!showAddFdrModal && !showEditFdrModal && !showFdrModal">
        <div v-show="selected == 1" class="board-wrapper pt-5">
          <fdr-kanban :items="filteredFdrs" />
        </div>

        <div style="position: relative" v-if="selected == 0">
          <FdrTable
            class="mt-3"
            @showInitiatives="showInitiativesFunction"
            @showItem="$router.push({ name: 'show-fdr' })"
            @editItem="$router.push({ name: 'edit-fdr' })"
            @transferItem="showTransferFdr = !showTransferFdr"
            :items="filteredFdrs"
            :loading="loadingData"
            @deleteItem="deleteItem"
          />
        </div>

        <div v-if="selected == 2" class="card p-3 mt-4">
          <Gantt
            :items="filteredFdrs"
            :loadingData="loadingData"
            :mode="mode"
            @showInitiatives="showInitiativesFunction"
          />
        </div>
      </div>
    </section>

    <div to="modals-xyz-548" v-if="showInitiatives">
      <modal @close="showInitiatives = !showInitiatives">
        <initiative-list :id="selectedfdr" />
      </modal>
    </div>
  </div>
</template>

<script>
import fdrKanban from "./components/fdrKanban";
import DropDownCustom from "@/components/custom/DropDown.vue";
import FdrTable from "./components/FdrTable.vue";
import Gantt from "./components/Gantt.vue";
import Modal from "@/components/custom/Modal.vue";
import InitiativeList from "./components/InitiativeList.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";
export default {
  name: "gallery",
  components: {
    fdrKanban,
    DropDownCustom,
    FdrTable,
    Gantt,
    Modal,
    InitiativeList,
    CustomAddButton,
  },
  data() {
    return {
      selectedfdr: null,
      showModal: false,
      showFdrModal: false,
      showAddFdrModal: false,
      showEditFdrModal: false,
      mode: "day",
      debugEventLog: [],

      selected: this.$store.state.views.activeView,
      tableItems: [],

      selectedRecords: [],
      filtered: false,
      showFilter: false,

      showInitiatives: false,
      actions: ["show", "delete"],
      searchQuery: {
        libelle: "",
        responsable: "",
        status: "",
        startedAt: "",
        endingAt: "",
        echeance: "",
      },
      loadingData: true,
      ganttView: [
        {
          text: "Jour",
          id: 1,
          color: " btn-inverse-primary",
        },
        {
          text: "Semaine",
          id: 2,
          color: "btn-inverse-danger ",
        },
        {
          text: "Mois",
          id: 3,
          color: "btn-inverse-info ",
        },
        {
          text: "Année",
          id: 4,
          color: "btn-inverse-secondary ",
        },
      ],
      selectedView: {
        text: "Jour",
        id: 1,
        color: " btn-inverse-primary",
      },
    };
  },
  methods: {
    showInitiativesFunction(id) {
      this.showInitiatives = !this.showInitiatives;
      this.selectedfdr = id;
    },
    delayByDays(date) {
      return parseInt(
        moment
          .duration(
            moment(date, "DD-MM-YYYY").diff(moment(new Date(), "DD-MM-YYYY"))
          )
          .asDays()
      );
    },

    changeView: function (val) {
      this.mode =
        val == 0 ? "day" : val == 1 ? "week" : val == 2 ? "month" : "year";
      this.selectedView = this.ganttView[val];
    },
    changeShowFilter: function () {
      this.showFilter = !this.showFilter;
    },
    onValueChange: function (e) {
      this.selected = e;

      this.$store.dispatch("views/changeView", e);
    },

    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        text: "Si vous supprimez cette FDR, vous supprimerez également les éléments de liaison (initiatives, finance ...)!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("fdr/deleteFdr", id).then(() => {
            this.tableItems = this.tableItems.filter((item) => item.id !== id);
            Swal.fire("Supprimé!", "", "success");
          });
        }
      });
    },
    echeanceStatus: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "Respectée";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "En retard";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "En cours";
      else return "Nouveau";
    },
  },

  computed: {
    ...mapGetters("fdr", ["FDRS"]),
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("cStatus", ["STATUS_FEUILLES"]), //get all status

    ...mapGetters(["isAdmin", "isManager", "isSuper", "isPo"]),

    responsables() {
      var data = this.RESPONSABLES.map((responsable) => ({
        // value: `/api/users/${responsable.id}`,
        id: responsable.id,
        value: `${responsable.firstname} ${responsable.lastname}`,
      }));

      return data;
    },
    statusFdr() {
      return this.STATUS_FEUILLES;
    },
    filteredFdrs: function () {
      if (this.tableItems.length) {
        var byName = this.tableItems.filter(
          (fdr) =>
            fdr.name
              .toLowerCase()
              .indexOf(
                this.searchQuery.libelle
                  ? this.searchQuery.libelle.toLowerCase()
                  : ""
              ) > -1 &&
            fdr.status
              .toLowerCase()
              .indexOf(
                this.searchQuery.status
                  ? this.searchQuery.status.toLowerCase()
                  : ""
              ) > -1 &&
            (this.searchQuery.endingAt
              ? moment(fdr.endingAt, "DD/MM/YYYY").format("YYYY/MM/DD") <=
                moment(this.searchQuery.endingAt, "DD/MM/YYYY").format(
                  "YYYY/MM/DD"
                )
              : true) &&
            (this.searchQuery.startedAt
              ? moment(fdr.startedAt, "DD/MM/YYYY").format("YYYY/MM/DD") >=
                moment(this.searchQuery.startedAt, "DD/MM/YYYY").format(
                  "YYYY/MM/DD"
                )
              : true) &&
            (this.searchQuery.echeance
              ? fdr.echeance
                  .toLowerCase()
                  .indexOf(this.searchQuery.echeance.toLowerCase()) > -1
              : true)
        );

        return this.searchQuery.responsable != "" &&
          this.searchQuery.responsable != null
          ? byName.filter(
              (fdr) =>
                fdr.responsable["@id"] ===
                `/api/users/${parseInt(this.searchQuery.responsable)}`
            )
          : byName;
      }
      return this.tableItems;
    },
  },

  mounted: function () {
    window.document.querySelectorAll(".resp_col").forEach((ele) => {
      ele.addEventListener("click", () => {});
    });
  },

  updated: function () {
    let progresses = document.querySelectorAll(".bar-progress");
    if (progresses.length > 0) {
      progresses.forEach((it) => {
        var avancement = parseInt(
          it.parentElement.lastElementChild.innerHTML.split("%")[0]
        );
        var color =
          avancement > 20 && avancement <= 70
            ? "#f5f245"
            : avancement > 70
            ? "#23d92c"
            : "#f5ba45";

        it.style.fill = color;
      });
    }
  },
  created() {
    this.$store.dispatch("fdr/fetchAllFdrs").then(() => {
      this.loadingData = false;
    });
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("cStatus/fetchAllStatusByType", "Feuille");
  },
  watch: {
    FDRS: function () {
      //window.console.table(this.FDRS);
      this.tableItems = this.FDRS.map((item) => ({
        ...item,
        actions: this.actions,
        echeance: this.echeanceStatus(
          item.createdAt,
          item.endingAt,
          item.status
        ),

        deadline: {
          start_date: item.startedAt,
          end_date: item.endingAt,
        },
        // progress: parseInt(Math.random() * 100), //missing
      }));
    },
  },
};
</script>

<style scoped>
.txt_desc {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.table_responsivo {
  overflow: scroll;
}

.popUpFather {
  position: relative;
}

.modal-xyz-548 {
  position: absolute;
  width: calc(100% - 260px);
  height: 100%;
  top: 0;
  right: 0;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .modal-xyz-548 {
    width: 100%;
  }
}

.tr_clckble {
  cursor: pointer;
}

.tr_clckble:hover {
  background-color: rgba(139, 44, 235, 0.342);
}

.hdr {
  background-color: #072648;
  text-align: center;
}

.hdr > p {
  color: white;
}

.spn {
  color: #797979;
}
</style>

<style>
.drag-column {
  min-width: 275px !important;
  width: 275px !important;
}

.v-select div {
  height: 100% !important;
}
.vs__search {
  color: #cfcfcf;
}
.mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.mx-datepicker .mx-input-wrapper,
.mx-datepicker input {
  height: 100% !important;
}
::-webkit-input-placeholder {
  color: #cfcfcf;
}
#dropdown-1__BV_toggle_ {
  background: #38b000 !important;
  border-color: #38b000 !important;
  width: 140px !important;
}
.custom-drop .dropdown-item,
.custom-drop .dropdown-menu {
  padding: 0 !important;
}

/* 
 */
</style>
